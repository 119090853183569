@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

/* Custom theme colors */
.bg-orange-500 {
  background-color: #f97316;
}

.text-orange-500 {
  color: #f97316;
}

@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');

body {
    font-family: 'Bebas Neue', sans-serif;
}

.bebas-neue-regular {
    font-family: "Bebas Neue", sans-serif;
    font-weight: 400;
    font-style: normal;
}

.fixed-bottom-right a {
    text-decoration: none;
    color: white !important;
}

.fixed-bottom-right a:hover {
  color: #1f2937 !important;
}




